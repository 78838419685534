// import actions
import moduleAction from './actions/module'
import profileAction from './actions/profile'
import userAction from './actions/user'
import permissionAction from './actions/permission'

const actions = {
  ...moduleAction,
  ...profileAction,
  ...userAction,
  ...permissionAction,
}

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
