import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import Auth from './devida/modules/auth/index'
import Pppctd from './devida/modules/ppptcd'
import Setting from './devida/modules/setting'
import Services from './devida/modules/services'
import Pdf from './devida/modules/pdf'
import Report from './devida/modules/report'
import Security from './devida/modules/security'

import Rrhh from './modules/rrhh'
import Comercial from './modules/comercial'
import Multimedia from './modules/multimedia/index'
import Catalogo from './modules/catalogo'
import Seguridad from './modules/seguridad'
import Helpers from './modules/helpers'
import Inventario from './modules/inventario'
import Vigilancia from './modules/vigilancia'
import Facturacion from './modules/facturacion'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    auth: Auth,
    ppptcd: Pppctd,
    setting: Setting,
    services: Services,
    pdf: Pdf,
    report: Report,
    security: Security,

    rrhh: Rrhh,
    vigilancia: Vigilancia,
    comercial: Comercial,
    multimedia: Multimedia,
    catalogo: Catalogo,
    inventario: Inventario,
    facturacion: Facturacion,
    seguridad: Seguridad,
    helpers: Helpers,
  },
  strict: process.env.DEV,
})
