export default [
  {
    path: '/seguridad/perfiles',
    name: 'rt-seguridad-perfiles',
    component: () => import('@/views/devida/security/profile/PerfilList.vue'),
    meta: {
      resource: 'rt-seguridad-sistemas',
      pageTitle: 'Perfiles',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Perfiles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seguridad/usuarios',
    name: 'rt-seguridad-usuarios',
    component: () => import('@/views/devida/security/user/UserList.vue'),
    meta: {
      resource: 'rt-seguridad-usuarios',
      pageTitle: 'Usuarios',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Usuarios',
          active: true,
        },
      ],
    },
  },
]
